import React, { Component } from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import classNames from 'classnames'
import { getLink } from 'src/utils'
import styles from './Search.module.scss'

const getUrl = (templateKey, url) => `${templateKey.match('DatoCmsInterview') ? 'interview' : 'news-list'}/${url}`

class Search extends Component {
  state = {
    isSearchShown: false,
    query: '',
    searchResults: [],
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleEscapeClick, false)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleEscapeClick, false)
  }

  disableScroll = () => {
    if (typeof window !== 'undefined') {
      const scrollbarWidth = window.innerWidth - window.document.documentElement.clientWidth
      window.document.body.classList.add('modal-open')
      window.document.body.style.paddingRight = `${scrollbarWidth}px`
    }
  }

  handleSearchClick = () => {
    this.disableScroll()
    this.setState(
      {
        isSearchShown: true,
      },
      () => this.searchInput.focus(),
    )
  }

  handleSearchChange = (event) => {
    const query = event.target.value
    const searchResults = query.length >= 1 ? this.getSearchResults(`${query}* ${query}`) : []
    this.setState((s) => {
      return {
        ...s,
        searchResults,
        query,
      }
    })
  }

  handleEscapeClick = (event) => {
    if (event.keyCode === 27) {
      this.handleCloseClick()
    }
  }

  handleCloseClick = () => {
    window.document.body.classList.remove('modal-open')
    window.document.body.style.paddingRight = `0px`
    this.setState(
      {
        query: '',
        searchResults: [],
        isMenuShown: false,
        isSearchShown: false,
      },
      () => (this.searchInput.value = ''),
    )
  }

  getSearchResults(query) {
    if (!query || !window.__LUNR__) return []
    const searchResults = window.__LUNR__.index.search(query)
    return searchResults.map(({ ref }) => window.__LUNR__.store[ref])
  }

  render() {
    const { className, locale, intl, closeMenu } = this.props

    return (
      <div className={classNames(className, styles.root)}>
        <div className={styles.search} onClick={this.handleSearchClick} tabIndex="0" />
        <div className={this.state.isSearchShown ? styles.overlayShown : styles.overlayHidden}>
          <div className={styles.content}>
            <button className={styles.close} onClick={this.handleCloseClick}>
              ✕
            </button>
            <input
              type="text"
              placeholder={intl.formatMessage({ id: 'home.search' })}
              className={styles.searchInput}
              onChange={this.handleSearchChange}
              ref={(input) => {
                this.searchInput = input
              }}
              aria-label="search"
            />
            <div className={styles.searchResults}>
              <ul className={styles.searchResultsList}>
                {this.state.searchResults
                  .filter((item) => item.locale === locale && item.title !== '_')
                  .filter(
                    (item) => !!item.templateKey.match('DatoCmsNews') || !!item.templateKey.match('DatoCmsInterview'),
                  )
                  .map((item) => (
                    <li key={item.url} className={styles.searchResultsItem} onClick={this.handleCloseClick}>
                      <Link to={getLink(locale, getUrl(item.templateKey, item.url))} onClick={closeMenu}>
                        {item.title}
                      </Link>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Search.propTypes = {
  className: PropTypes.string,
  locale: PropTypes.string,
}

export default injectIntl(Search)
