import React, { Component } from 'react'
import PropTypes from 'prop-types'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import { FormattedMessage, injectIntl } from 'react-intl'
import styles from './SubscribeAdditional.module.scss'
import classNames from 'classnames'

class SubscribeAdditional extends Component {
  state = {
    email: '',
    status: null,
    detail: null,
    loading: false,
    error: null,
  }

  handleSubmit = (event) => {
    const { email } = this.state
    this.setState({
      loading: true,
      error: null,
    })

    event.preventDefault()
    addToMailchimp(email)
      .then((data) => {
        this.setState(() => {
          return {
            status: data.result,
          }
        })
      })
      .then(() => {
        this.setState({ loading: false })
        if (this.state.status === 'success') this.setState({ email: '' })
      })
      .catch((error) => {
        this.setState({ error })
      })
  }
  handleOnChange = (event) => {
    this.setState({ email: event.target.value })
  }

  render() {
    const { email, status, loading } = this.state
    const { intl } = this.props
    return (
      <div className={styles.root}>
        <div className={styles.inner}>
          <h1 className={styles.title}>
            <FormattedMessage id="home.subscribe" />
          </h1>
          <div className={styles.inputField}>
            <form
              className={classNames(styles.form, {
                [styles.success]: status === 'success',
                [styles.error]: status === 'error',
              })}
              method="post"
              action=""
            >
              {status && (
                <span
                  className={styles.status}
                  style={status === 'success' ? { color: 'lightgreen' } : { color: 'tomato' }}
                >
                  {status === 'success' && intl.formatMessage({ id: 'home.successfully' })}
                  {status === 'error' && intl.formatMessage({ id: 'home.mistake' })}
                </span>
              )}

              <input
                className={styles.input}
                name=""
                type="email"
                placeholder={intl.formatMessage({ id: 'home.email' })}
                autoComplete="on"
                value={email}
                disabled={status === 'success'}
                onChange={(event) => this.setState({ email: event.target.value })}
              />
              {loading ? (
                <button className={styles.button} onClick={this.handleSubmit}>
                  <FormattedMessage id="home.loading" />
                </button>
              ) : (
                <button className={styles.button} onClick={this.handleSubmit}>
                  <FormattedMessage id="home.send" />
                </button>
              )}
            </form>
            <p className={styles.clarification}>
              <FormattedMessage id="home.subscribeClarification" />
            </p>
          </div>
        </div>
      </div>
    )
  }
}

SubscribeAdditional.propTypes = {
  locale: PropTypes.string,
}

export default injectIntl(SubscribeAdditional)
