import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import styles from './InterviewsList.module.scss'
import SortPanel from 'src/components/SortPanel'
import { injectIntl } from 'react-intl'
import ItemWithoutPreview from 'src/components/Home/ItemWithoutPreview'
import { handleSortByOldDate, handleSortByNewDate, formateDate } from 'src/utils'

class InterviewsList extends Component {
  state = { interviews: [] }

  componentDidMount() {
    this.setState({ interviews: handleSortByNewDate(this.props.interviews.edges) })
  }

  handleSort = (ev) => {
    let activeOption = ev.target.value
    const interviews = this.props.interviews.edges
    formateDate(interviews, 'publishDate')

    switch (activeOption) {
      case 'old':
        this.setState({ interviews: handleSortByOldDate(interviews) })
        break
      case 'new':
        this.setState({ interviews: handleSortByNewDate(interviews) })
        break
      default:
        this.setState({ interviews: handleSortByNewDate(interviews) })
        break
    }
  }

  render() {
    const {
      className,
      interviews: { edges },
      locale,
      intl,
    } = this.props

    const sortOptions = [
      { type: 'new', text: intl.formatMessage({ id: 'home.fromNewToOld' }) },
      { type: 'old', text: intl.formatMessage({ id: 'home.fromOldToNew' }) },
    ]
    const articles = [...edges.filter(({ node: { title } }) => title !== null)]

    return (
      <>
        <SortPanel
          title={intl.formatMessage({ id: 'home.AllInterviews' })}
          options={sortOptions}
          sortTitle={intl.formatMessage({ id: 'home.Sort' })}
          onGetOption={(ev) => this.handleSort(ev)}
        />

        <div>
          <section className={classNames(className, styles.root)}>
            <div className={styles.articles}>
              {articles.map((item) => <ItemWithoutPreview locale={locale} key={item.node.slug} {...item} />)}
            </div>
          </section>
        </div>
      </>
    )
  }
}

InterviewsList.propTypes = {
  className: PropTypes.string,
  edges: PropTypes.array,
  locale: PropTypes.string.isRequired,
}

export default injectIntl(InterviewsList)
