import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { slide as Menu } from 'react-burger-menu'
import Search from 'src/components/Search'
import styles from './Sidebar.module.scss'
// import { formatMessage } from 'src/translations'
import { FormattedMessage } from 'react-intl'
import { isOnline, removeListeners, getLink } from 'src/utils'
import { injectIntl } from 'react-intl'
const linksList = [
  { id: 6, to: 'news-list', translationKey: 'home.news' },
  { id: 1, to: 'stories-list', translationKey: 'home.stories' },
  { id: 2, to: 'interviews', translationKey: 'home.interview' },
  { id: 3, to: 'videos', translationKey: 'home.video' },
  { id: 4, to: 'podcasts', translationKey: 'home.podcasts', offline: true },
  { id: 7, to: 'advices', translationKey: 'home.advices' },
  // ***Temporarily disabled ***
  // { id: 8, to: 'jobs', translationKey: 'home.jobs' },
  { id: 5, to: 'contacts', translationKey: 'home.contacts' },
]

class SideBar extends Component {
  state = {
    menuOpen: false,
    isOnline: true,
  }

  componentDidMount() {
    isOnline(this)
  }

  componentWillUnmount() {
    removeListeners()
  }

  disableScroll = () => {
    if (typeof window !== 'undefined') {
      const scrollbarWidth = window.innerWidth - window.document.documentElement.clientWidth
      window.document.body.classList.add('modal-open')
      window.document.body.style.paddingRight = `${scrollbarWidth}px`
    }
  }

  enableScroll = () => {
    if (typeof window !== 'undefined') {
      window.document.body.classList.remove('modal-open')
      window.document.body.style.paddingRight = `0px`
    }
  }

  handleClick = (event) => {
    event.preventDefault()
    this.disableScroll()
  }

  handleStateChange = (state) => {
    this.state.menuOpen ? this.enableScroll() : this.disableScroll()
    this.setState({ menuOpen: state.isOpen })
  }

  closeMenu = () => {
    this.setState({ menuOpen: false }, () => {
      this.enableScroll()
    })
  }

  render() {
    const { locale, intl } = this.props

    return (
      <Menu isOpen={this.state.menuOpen} onStateChange={(state) => this.handleStateChange(state)} right width={'100%'}>
        {linksList.map(({ id, to, translationKey, offline }) => {
          return (
            <Link
              key={id}
              onClick={this.closeMenu}
              to={getLink(locale, to)}
              style={
                ((locale === 'en' && offline) || (!this.state.isOnline && offline)) && {
                  opacity: '0.3',
                  pointerEvents: 'none',
                }
              }
            >
              {intl.formatMessage({ id: translationKey })}
            </Link>
          )
        })}

        <li className={styles.about}>
          <a href={`https://about.theheroes.media/${locale}`} target="_blank" rel="noopener noreferrer">
            <FormattedMessage id="home.aboutButton" />
          </a>
        </li>

        <li className={styles.becomeHeroBtn}>
          <Link to={getLink(locale, 'become-a-hero')}>
            <FormattedMessage id="home.becomeHero" />
          </Link>
        </li>

        <nav className={styles.controls}>
          <ul style={{ listStyle: 'none' }}>
            <li>
              <Link className={styles.controls} to={getLink(locale, '/')} onClick={this.handleClick}>
                <Search closeMenu={this.closeMenu} locale={locale} />
              </Link>
            </li>
          </ul>
        </nav>
      </Menu>
    )
  }
}

SideBar.propTypes = {
  locale: PropTypes.string,
}

export default injectIntl(SideBar)
