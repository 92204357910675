export default {
  newsButton: 'Новости',
  storiesButton: 'Сторис',
  interviewButton: 'Интервью',
  videosButton: 'Видео',
  podcastsButton: 'Подкасты',
  aboutButton: 'О проекте',
  contactsButton: 'Контакты',
  topButton: 'наверх',
  subscribed: 'Подписка успешно оформлена',
  subscribeButton: 'Подписаться',
  summary: 'Выводы',
  interlocutor: 'Беседовал',
  photo: 'Фото',
  author: 'Автор',
  listenButton: 'Слушать',
  news: 'Новости',
  stories: 'Сторис',
  interview: 'интервью',
  video: 'видео',
  allVideos: 'Все видео',
  podcasts: 'подкасты',
  about: 'О проекте',
  contacts: 'Контакты',
  top: 'Наверх',
  home: 'На главную',
  audio: 'подкасты',
  advertising: 'Блок рекламы',
  subscribeDescription:
    'Ваша еженедельная справка о навигации по возрасту индексного фонда, понимании экономики и взломе капитализма:',
  subscribeTitle: 'Как найти идею и вырастить ее до миллиардной компании?',
  subscribeUnder: 'Одно сообщение в неделю, никакого спама*',
  share: 'Поделиться',
  shareStuff: 'Поделиться материалом:',
  loading: 'Загрузка...',
  successfully: 'Подписка успешно оформлена!',
  mistake: 'Ошибка',
  duration: 'длительности',
  date: 'дате добавления',
  Allpodcasts: 'Все подкасты',
  Sortby: 'Сортировать по',
  Sort: 'Сортировать:',
  fromNewToOld: 'от новых к старым',
  fromOldToNew: 'от старых к новым',
  AllInterviews: 'Все интервью',
  AllNews: 'Все новости',
  interviews: 'ИНТЕРВЬЮ',
  newsUpperCase: 'НОВОСТИ',
  search: 'Поиск...',
  dateAdd: 'дате добавления',
  AllVideos: 'Все видео',
  email: 'Ваш email',
  missingVideo: 'Видео не найдены',
  missingPodcasts: 'Подкасты не найдены',
  missingNews: 'Новости не найдены',
  offlineContent: 'Этот контент доступен только онлайн',
  selectJobCategories: 'Вакансии: ',
  searchByKeyword: 'Искать по слову',
  clearFilters: 'Очистить фильтр',
  searchResult: 'Результаты поиска',
  jobs: 'Работа',
  tags: 'Теги: ',
  viewAllJobs: 'Все вакансии',
  gems: 'Понравилось?',
  fullDescription: 'Читать все описание',
  showLess: 'Свернуть',
  location: 'Расположение',
  aboutCompany: 'О компании',
  respond: 'Откликнуться',
  becomeBetter: 'узнать как',
  advices: 'Лайфхаки',
  jobsButton: 'Работа',
  advicesButton: 'Лайфхаки',
  slogan: 'Платформа для IT-сообщества и предпринимателей',
  becomeHero: 'Стать героем',
  subscribe: 'Подписаться на новости проекта',
  jobTitle: 'Вакансия',
  projectPartners: 'Партнёры',
  description: 'Описание вакансии',
  geolocation: 'Месторасположение',
  locationDescription: 'Описание месторасположения',
  companyName: 'Компания*',
  companyLogo: 'Логотип компании',
  experienceLevel: 'Уровень опыта',
  jobCategory: 'Категории',
  contactEmail: 'Email*',
  city: 'Локация (регион или город)*',
  addNewJob: 'Добавить вакансию',
  sendRequest: 'Отправить заявку',
  vacancyName: 'Название Вакансии*',
  jobDescription: 'Описание вакансии*',
  position: 'Тип занятости*',
  tag: 'Скиллы',
  salaryFrom: 'Зарплата от (usd)',
  salaryUp: 'Зарплата до (usd)',
  expirationDate: 'Крайний срок подачи',
  yourName: 'Ваши имя и фамилия',
  projectName: 'Название проекта',
  projectDescription: 'Описание проекта: проблема, решение, актуальность',
  subscribeToNews: 'Подписаться на новости',
  footerCopyright: ' © 2019 ООО «Аивитам», ',
  footerSlogan: 'ДУМАЙ ГЛОБАЛЬНО, ДЕЙСТВУЙ ЛОКАЛЬНО',
  connect: 'Связаться',
  send: 'Отправить',
  subscribeClarification: 'Одно сообщение в неделю, никакого спама!',
  writeUs: 'Напишите нам:',
  callUs: 'Позвоните нам:',
  ourAddress: 'Наш адрес: ',
  applicationSuccess: 'Заявка успешно отправлена',
  submitDesc: 'мммммммм ооаоаоаоаоаоаааа',
  freeAdding:
    'Мы запускаем Beta версию платформы рекрутинга для стартапов и ИТ-компаний. Публикуем вакансии и помогаем специалистам в поиске работы. На этапе запуска размещение вакансий бесплатно – через премодерацию.',
  all: 'Все',
  pageNotFound: 'Такой страницы на сайте нет! Но есть много других:',
  readInteviews: 'Читайте интервью',
  readNews: 'Читайте новости',
  watchVideo: 'Смотрите видео',
  listenPodcasts: 'Слушайте подкасты',
  shareLifehacks: 'Делитесь лайфхаками',
  becomeAprojectHero: 'Станьте героем проекта',
  name: 'Ваше имя',
  phone: 'Ваш номер телефона',
  message: 'Ваше сообщение',
  errorMessage: 'Пожалуйста заполните обязательные поля',

  adviceFormSuccess: 'Отправлено успешно!',
  belgos: 'Страховой партнер',
  factory: 'Фабрика',
  siteTitle: 'theheroes.media – платформа для ИТ - сообщества и предпринимателей',
  siteDescription: `Международная платформа для новых героев – ИТ предпринимателей.
  Выходит на русском и английском языках. Интервью и истории, личный опыт и лайфхаки, видеоканал и мероприятия, рекрутинг и фабрика стартапов.`,
  addJobSuccess: `Спасибо, что Вы с нами!{breakingLine}После модерации Ваша вакансия будет опубликована!`,
  fullNews: 'Читать всю новость',
  files: 'Файлы',
  logoFile: 'Добавить лого',
  photoFiles: 'Добавить фото команды/офиса',
  uploadLogoError: 'При загрузке фото произошла ошибка',
  more: 'Еще...',
}
