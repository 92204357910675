export default {
  newsButton: 'News',
  storiesButton: 'Stories',
  interviewButton: 'Interviews',
  videosButton: 'Videos',
  podcastsButton: 'Podcasts',
  aboutButton: 'About',
  contactsButton: 'Contacts',
  topButton: 'top',
  subscribed: 'You have been subscribed',
  subscribeButton: 'Subscribe',
  summary: 'Summary',
  interlocutor: 'Interlocutor',
  photo: 'Photo',
  author: 'Author',
  listenButton: 'Listen',
  news: 'News',
  stories: 'Stories',
  interview: 'interviews',
  video: 'videos',
  allVideos: 'All videos',
  podcasts: 'podcasts',
  about: 'About',
  contacts: 'Contacts',
  top: 'top',
  home: 'home',
  audio: 'audio',
  advertising: 'advertising',
  subscribeDescription:
    'Your weekly reference for navigating the age of the index fund, understanding the economy and hacking capitalism:',
  subscribeTitle: 'How to find an idea and grow it to a billion-dollar company?',
  subscribeUnder: 'One message per week, no spam *',
  share: 'share',
  shareStuff: 'Share:',
  loading: 'Loading...',
  successfully: 'Subscription successfully issued!',
  mistake: 'Incorrect email address',
  duration: 'duration',
  date: 'date',
  Allpodcasts: 'All podcasts',
  Sortby: 'Sort by',
  Sort: 'Sort:',
  fromNewToOld: 'from new to old',
  fromOldToNew: 'from old to new',
  AllInterviews: 'All interviews',
  AllNews: 'All news',
  interviews: 'INTERVIEWS',
  newsUpperCase: 'NEWS',
  search: 'Search...',
  dateAdd: 'date',
  AllVideos: 'All videos',
  email: 'Your email',
  missingVideo: 'No videos found',
  missingPodcasts: 'No podcasts found',
  missingNews: 'No news found',
  offlineContent: 'This content is only available online.',
  selectJobCategories: 'Vacancies: ',
  searchByKeyword: 'Search by keyword',
  clearFilters: 'Clear filters',
  searchResult: 'Search results',
  jobs: 'Jobs',
  tags: 'Tags: ',
  viewAllJobs: 'View all jobs',
  gems: 'Like these gems?',
  fullDescription: 'Read full job description',
  showLess: 'Show less',
  becomeBetter: 'find out how',
  location: 'Location',
  respond: 'Respond',
  advices: 'Lifehacks',
  jobsButton: 'Jobs',
  projectPartners: 'Partners',
  advicesButton: 'Lifehacks',
  slogan: 'Platform for IT community and entrepreneurs',
  becomeHero: 'Become a hero',
  subscribe: ' Subscribe to the project news',
  jobTitle: 'Vacancy',
  description: 'Description',
  geolocation: 'Geolocation',
  locationDescription: 'Location description',
  companyName: 'Company*',
  companyLogo: 'Company logo',
  aboutCompany: 'About company',
  experienceLevel: 'Experience level',
  jobCategory: 'Category',
  contactEmail: `Email*`,
  city: 'Location*',
  addNewJob: 'Add vacancy',
  sendRequest: 'Send request',
  vacancyName: 'Job Title*',
  jobDescription: 'Job Description*',
  position: 'Position*',
  tag: 'Skills',
  salaryFrom: 'Salary from (usd)',
  salaryUp: 'Salary up (usd)',
  expirationDate: 'Expiration date',
  yourName: 'Your first and lastname',
  projectName: 'Project name',
  projectDescription: 'Project description: problem, solution, relevance',
  subscribeToNews: 'Subscribe to news',
  footerCopyright: '© 2019 AIVITAM, LLC, ',
  footerSlogan: 'THINK GLOBALLY, ACT LOCALLY',
  connect: 'Connect',
  send: 'Send',
  subscribeClarification: 'One message per week, no spam!',
  writeUs: 'Write us: ',
  callUs: 'Call us: ',
  ourAddress: 'Our address:',
  applicationSuccess: 'Application successfully submitted',
  submitDesc: 'mmmmmmmmmm oaoaoaoaoaooa',
  freeAdding:
    'We are launching the Beta version of the recruiting platform for startups and IT companies. We publish vacancies and help specialists in job search. At the launch stage, posting vacancies is free through pre-moderation.',
  all: 'All',
  pageNotFound: 'Page not found! But there are many others:',
  readInteviews: 'Read interviews',
  readNews: 'Read news',
  watchVideo: 'Watch videos',
  listenPodcasts: 'Listen to podcasts',
  shareLifehacks: 'Share lifehacks',
  becomeAprojectHero: 'Become a hero of the project',
  errorMessage: 'Please fill in required fields',

  name: 'Your name',
  phone: 'Your phone number',
  message: 'Your message',

  adviceFormSuccess: 'Sent successfully!',
  belgos: 'Insurance partner',
  factory: 'Factory',
  siteTitle: 'theheroes.media - a platform for the IT community and entrepreneurs',
  siteDescription: `International platform for new heroes - IT entrepreneurs.
  It is published in Russian and English versions. Interviews and stories, personal experience and lifehacks, video channel and events, recruiting and startup factory.`,
  addJobSuccess: 'Thank you!{breakingLine}Your vacancy would be published after moderation',
  fullNews: 'Read more',
  files: 'Files',
  logoFile: 'Add logo',
  photoFiles: 'Add a photo of the team/office',
  uploadLogoError: 'An error occurred while uploading photos',
  more: 'More...',
}
