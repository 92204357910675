import React, { Component } from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import SocialShare from 'src/components/SocialShare'
import ListenPodcast from 'src/components/ListenPodcast'
import Img from 'gatsby-image'
import styles from './ItemWithoutPreview.module.scss'
import { getBaseUrl } from 'src/utils'
import { isOnline, removeListeners, getLink } from 'src/utils'

class ItemWithoutPreview extends Component {
  state = { isOnline: true }

  componentDidMount() {
    isOnline(this)
  }

  componentWillUnmount() {
    removeListeners()
  }

  render() {
    const {
      node: { className, title, image, templateKey, slug, layoutFlag, podcast },
      locale,
      isFullWidth,
      onHomePage,
      podcasts,
    } = this.props

    const podcastSlag =
      podcasts && podcast && podcast[0] && podcasts.filter(({ node }) => node.podcast.url === podcast[0].podcast.url)

    const tags = {
      Interview: locale === 'ru' ? 'ИНТЕРВЬЮ' : 'INTERVIEWS',
    }

    const podcastEl = (
      <ListenPodcast
        isMobileHidden={true}
        podcast={podcast && podcast.length > 0 && podcast[0]}
        parentTemplateKey={templateKey}
        parentSlug={podcastSlag && podcastSlag.length > 0 && podcastSlag[0].node.slug}
        locale={locale}
      />
    )

    const socialShareEl = (
      <SocialShare title={title} url={`${getBaseUrl()}${locale}/interview/${slug}`} isWhite={true} locale={locale} />
    )

    return (
      <div
        className={classNames(
          styles.link,
          { [styles.link2]: layoutFlag, [styles.isFullWidth]: isFullWidth },
          onHomePage && styles.linkOnHomePage,
        )}
      >
        <article className={classNames(className, styles.root)}>
          <Link to={getLink(locale, `interview/${slug}`)}>
            {image && <Img fluid={image.fluid} className={styles.coverWithoutPreview} />}
          </Link>
          {podcast &&
            podcast.length > 0 &&
            podcast[0] &&
            (this.state.isOnline ? podcastEl : <div className={styles.offlineMode}>{podcastEl}</div>)}

          <div className={styles.innerWithoutPreview}>
            <Link to={getLink(locale, `interview/${slug}`)} className={styles.title}>
              <span>{title && title.length >= 240 ? `${title.substring(0, 240)}...` : title}</span>
            </Link>

            <div style={{ display: 'flex', alignItems: 'space-between' }} className={styles.tags}>
              {/* TODO: Fix link */}
              <Link to={getLink(locale, `interviews`)}>
                <span className={styles.tagWithoutContent}>{tags.Interview}</span>
              </Link>
              {this.state.isOnline ? socialShareEl : <div className={styles.offlineMode}>{socialShareEl}</div>}
            </div>
          </div>
        </article>
      </div>
    )
  }
}

ItemWithoutPreview.displayName = 'Home.ItemWithoutPreview'

ItemWithoutPreview.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.object,
  templateKey: PropTypes.string,
  slug: PropTypes.string,
}

export default ItemWithoutPreview
