import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from './SortPanel.module.scss'
import classNames from 'classnames'

class SortPanel extends Component {
  render() {
    const { title, options, onGetOption, sortTitle, removeMargin, hideSortPanel } = this.props
    return (
      <div
        className={classNames(styles.root, removeMargin && styles.removeMargin, hideSortPanel && styles.hideSortPanel)}
      >
        <div className={styles.inner}>
          <h2 className={styles.heading}>{title}</h2>
          <div className={styles.sort}>
            <div>{sortTitle}</div>
            <label>
              <select onChange={(ev) => onGetOption(ev)} className={styles.select}>
                {options.map((item, index) => (
                  <option value={item.type} key={item.type + index}>
                    {item.text}
                  </option>
                ))}
              </select>
            </label>
          </div>
        </div>
      </div>
    )
  }
}

SortPanel.propTypes = {
  title: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  sortTitle: PropTypes.string.isRequired,
  onGetOption: PropTypes.func.isRequired,
}

export default SortPanel
