import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Link } from 'gatsby'
import Search from 'src/components/Search'
import styles from './Header.module.scss'
import logo from 'src/img/heroes_logo_white.svg'
import SideBar from 'src/components/Sidebar'
import { isOnline, removeListeners, getLink } from 'src/utils'
import Langs from 'src/components/Langs'
import { FormattedMessage } from 'react-intl'

const isPartiallyActive = ({ isPartiallyCurrent }) => {
  return isPartiallyCurrent ? { className: classNames(styles.active) } : null
}

const PartialNavLink = (props) => <Link getProps={isPartiallyActive} {...props} />

class Header extends Component {
  state = { isOnline: true, lang: 'ru', url: '' }

  componentDidMount() {
    isOnline(this)

    if (typeof window !== 'undefined') {
      window.onscroll = () => {
        this.scrollHeader()
      }
    }
  }

  componentWillUnmount() {
    removeListeners(this)
  }

  scrollHeader = () => {
    if (document.getElementById('header')) {
      const headerStyle = document.getElementById('header').style
      if (document.body.scrollTop >= 1 || document.documentElement.scrollTop >= 1) {
        headerStyle.backgroundColor = 'rgba(0, 0, 0, 1)'
        this.setState({ isSloganShown: false })
      } else {
        headerStyle.backgroundColor = 'rgba(0, 0, 0, 0)'
        this.setState({ isSloganShown: true })
      }
    }
  }

  render() {
    const { className, full, locale, fixed } = this.props

    const onlineLinks = (
      <>
        <li>
          <PartialNavLink to={getLink(locale, 'podcasts')}>
            <FormattedMessage id="home.podcastsButton" />
          </PartialNavLink>
        </li>
      </>
    )

    const offineLinks = (
      <>
        <li className={styles.offlineMode}>
          <PartialNavLink to={getLink(locale, 'podcasts')}>
            <FormattedMessage id="home.podcastsButton" />
          </PartialNavLink>
        </li>
      </>
    )

    return (
      //TODO: this empty div fixes an issue with attributes disappearing from the first child
      <div>
        <div className={classNames(styles.wrap, { [styles.full]: full })}>
          <div className={styles.sidebar}>
            <SideBar locale={locale || this.props.locale} />
          </div>
          <header id="header" className={classNames(className, styles.root, { [styles.fixed]: fixed })}>
            <Link to={getLink(locale)} className={styles.logo}>
              <img className={styles.logoImg} src={logo} alt="logo" />
              <figure>BETA</figure>
            </Link>
            <nav className={styles.nav}>
              <ul>
                <li>
                  <PartialNavLink to={getLink(locale, 'news-list')}>
                    <FormattedMessage id="home.newsButton" />
                  </PartialNavLink>
                </li>
                <li>
                  <PartialNavLink to={getLink(locale, 'stories-list')}>
                    <FormattedMessage id="home.storiesButton" />
                  </PartialNavLink>
                </li>
                <li>
                  <PartialNavLink to={getLink(locale, 'interviews')}>
                    <FormattedMessage id="home.interviewButton" />
                  </PartialNavLink>
                </li>
                <li>
                  <PartialNavLink to={getLink(locale, 'videos')}>
                    <FormattedMessage id="home.videosButton" />
                  </PartialNavLink>
                </li>
                {this.state.isOnline && locale !== 'en' ? onlineLinks : offineLinks}
                <li>
                  <PartialNavLink to={getLink(locale, 'advices')}>
                    <FormattedMessage id="home.advicesButton" />
                  </PartialNavLink>
                </li>
                {/* ***Temporarily disabled *** */}
                {/* <li>
                  <PartialNavLink to={getLink(locale, 'jobs')}>
                    <FormattedMessage id="home.jobsButton" />
                  </PartialNavLink>
                </li> */}
              </ul>
            </nav>

            <nav className={styles.controls}>
              <ul className={styles.extra}>
                <li>
                  <Link to={getLink(locale, 'become-a-hero')} className={styles.becomeHeroBtn}>
                    <FormattedMessage id="home.becomeHero" />
                  </Link>
                </li>
                <li>
                  <Langs locale={locale} />
                </li>
              </ul>
              <div className={styles.search}>
                <Search locale={locale || this.props.locale} />
              </div>
            </nav>
          </header>
        </div>
      </div>
    )
  }
}

Header.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  full: PropTypes.bool,
  locale: PropTypes.string.isRequired,
}

export default Header
