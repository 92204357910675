import React, { Component } from 'react'
import classNames from 'classnames'
import whiteVolume from 'src/img/volumeWhite.svg'
import blackVolume from 'src/img/volume.svg'
import { PodcastContext } from 'src/components/PodcastLayout'
import PropTypes from 'prop-types'
import styles from './ListenPodcast.module.scss'

class ListenPodcast extends Component {
  render() {
    const {
      podcast: { podcast: podcastUrl, podcastTitle, podcastGuest, podcastCover, slug },
      style,
      reverse,
      isMobileHidden,
      parentSlug = slug,
      parentTemplateKey,
      locale,
      intro,
    } = this.props

    const listenText = locale === 'en' ? 'Listen' : 'Слушать'

    return (
      <PodcastContext.Consumer>
        {(contextValue) => {
          const { onChangeData } = contextValue
          return (
            <section>
              {podcastTitle &&
                podcastUrl && (
                  <div
                    style={style}
                    className={classNames(styles.callToAction, {
                      [styles.intro]: intro,
                    })}
                    onClick={(e) => {
                      e.preventDefault()
                      onChangeData(
                        podcastUrl.url,
                        podcastTitle,
                        podcastGuest,
                        podcastCover,
                        parentSlug,
                        parentTemplateKey,
                      )
                    }}
                  >
                    {reverse ? (
                      <>
                        <img
                          src={blackVolume}
                          style={{
                            width: '32px',
                            height: '32px',
                            marginRight: '15px',
                          }}
                          alt=""
                        />
                        <span className={styles.tag}>{listenText}</span>
                      </>
                    ) : (
                      <>
                        <span
                          className={classNames(styles.tag, {
                            [styles.isMobileHidden]: isMobileHidden,
                          })}
                        >
                          {listenText}
                        </span>

                        <img
                          src={whiteVolume}
                          style={{
                            width: '32px',
                            height: '32px',
                            marginLeft: '15px',
                          }}
                          alt=""
                        />
                      </>
                    )}
                  </div>
                )}
            </section>
          )
        }}
      </PodcastContext.Consumer>
    )
  }
}

ListenPodcast.propTypes = {
  podcastCover: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  podcastGuest: PropTypes.string,
  podcastTitle: PropTypes.string,
  style: PropTypes.object,
  reverse: PropTypes.bool,
  isMobileHidden: PropTypes.bool,
  intro: PropTypes.bool,
  slug: PropTypes.string,
  parentTemplateKey: PropTypes.string,
  locale: PropTypes.string,
}

export default ListenPodcast
