import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import { IntlProvider, addLocaleData } from 'react-intl'

import PageContext from './PageContext'

import ruData from 'react-intl/locale-data/ru'
import enData from 'react-intl/locale-data/en'
import { translations, languages } from 'src/i18n'

addLocaleData([...ruData, ...enData])

const withLayout = (customProps) => (PageComponent) => (props) => {
  const { locale } = props.pageContext

  const pageContextValue = { custom: customProps, page: props.pageContext }

  const defaultLocale = languages.find((language) => language.default).locale
  const pageLocale = locale || defaultLocale

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={(data) => (
        <IntlProvider locale={pageLocale} messages={translations[pageLocale]}>
          <PageContext.Provider value={pageContextValue}>
            <PageComponent locale={locale} {...props} />
          </PageContext.Provider>
        </IntlProvider>
      )}
    />
  )
}

withLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default withLayout
