import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

const PageHelmet = ({ title, description, keywords, image, url }) => {
  let imagePath = 'https://www.datocms-assets.com/9865/1564410058-heroes-media-black.svg'
  if (image) {
    imagePath = image
  }

  return (
    <Helmet defer={false}>
      {title && [
        <title key="title" itemProp="name">
          The Heroes | {title}
        </title>,
        <meta key="metaTitle" name="title" content={title} />,
        <meta key="ogTitle" property="og:title" content={title} />,
        <meta key="twitterTitle" name="twitter:title" content={title} />,
        <meta key="ogImage" property="og:image" content={imagePath} />,
        <meta key="twitterImage" name="twitter:image" content={imagePath} />,
        <meta key="twitterCard" name="twitter:card" content="summary_large_image" />,
        <meta key="image" itemprop="image" content={imagePath} />,
        <meta key="name" itemprop="name" content={title} />,
      ]}
      {url && <meta property="og:url" content={url} />}
      {keywords && <meta name="keywords" content={keywords} />}
      <meta key="description" name="description" content={description ? description : 'The Heroes'} />,
      <meta key="ogDescription" property="og:description" content={description ? description : 'The Heroes'} />,
      <meta key="twitterDescription" name="twitter:description" content={description ? description : 'The Heroes'} />,
    </Helmet>
  )
}

PageHelmet.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  keywords: PropTypes.string,
  image: PropTypes.string,
}

export default PageHelmet
