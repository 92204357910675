module.exports = [
  {
    locale: 'ru',
    label: 'Русский',
  },
  {
    locale: 'en',
    label: 'English',
    default: true,
  },
]
