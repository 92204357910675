import React, { useContext } from 'react'
import { navigate } from 'gatsby'
import { injectIntl } from 'react-intl'
import PageContext from 'src/components/withLayout/PageContext'
import styles from 'src/components/Header/Header.module.scss'

const Langs = ({ intl: { locale } }) => {
  const pageContext = useContext(PageContext)

  const handleSetLang = (language) => {
    const { originalPath } = pageContext.page
    const newPathname = `/${language}${originalPath}`

    localStorage.setItem('language', language)
    navigate(newPathname)
  }
  if (!pageContext.custom.localeKey) return null
  return (
    <div>
      <button className={styles.changeLanguage} onClick={() => handleSetLang(locale === 'en' ? 'ru' : 'en')}>
        <span className={styles.lang}>{locale === 'en' ? 'ru' : 'en'}</span>
      </button>
    </div>
  )
}

export default injectIntl(Langs)
